@font-face {
	font-family: 'Monoist';
	src: url('./Monoist-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Anton');

body {
	font-family: 'Monoist', 'Anton';
}
